import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useReducer, useState } from 'react';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import postCalculation from '../../api/calculation/postCalculation';
import { ReactComponent as LeftArrow } from '../../assets/arrow-left.svg';
import { ReactComponent as RightArrow } from '../../assets/arrow-right.svg';
import { ReactComponent as CalculatorLogo } from '../../assets/calculator.svg';
import Popup from '../../components/confirmationModal';
import Header from '../../components/header';
import Loader from '../../components/loader';
import Location from './components/location';
import CalculationResult from './components/result';
import VehicleDetails from './components/vehicleDetails';
import Wizard from './components/wizard';

export default function Calculation() {
  const initialCountryState = { name: null, code: '' };
  const initialCityState = { name: null, lat: null, lng: null };
  const [step, setStep] = useState(1);
  const [countryFrom, setCountryFrom] = useState(initialCountryState);
  const [countryTo, setCountryTo] = useState(initialCountryState);
  const [cityFrom, setCityFrom] = useState(initialCityState);
  const [cityTo, setCityTo] = useState(initialCityState);
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [vehiclesWithPrice, setVehicleWithPrice] = useState([]);
  const [distance, setDistance] = useState(0);
  const [jobUuid, setJobUuid] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [isAccepted, setIsAccepted] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  const defaultVehicle = {
    vehicleType: 'Standard',
    electric: false,
    nonRunner: false,
    amount: 1,
    id: 0,
  };

  const [vehicle, updateVehicleteState] = useReducer(
    (state, updates) => ({ ...state, ...updates }),
    defaultVehicle,
  );

  const [vehicles, setVehicles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const handleChangeCountryFrom = (country) => {
    document.getElementsByClassName('cityFrom')[0].value = '';
    if (country.code === 'GB' && countryTo.code !== 'GB') {
      document.getElementsByClassName('cityTo')[0].value = '';
      setCountryTo(country);
      setCityTo(initialCityState);
    } else if (country.code !== 'GB' && countryTo.code === 'GB') {
      document.getElementsByClassName('cityTo')[0].value = '';
      setCountryTo(initialCountryState);
      setCityTo(initialCityState);
    }
    setCityFrom(initialCityState);
    setCountryFrom(country);
  };
  const handleChangeCountryTo = (country) => {
    document.getElementsByClassName('cityTo')[0].value = '';
    if (country.code === 'GB' && countryFrom.code !== 'GB') {
      document.getElementsByClassName('cityFrom')[0].value = '';
      setCountryFrom(country);
      setCityFrom(initialCityState);
    } else if (country.code !== 'GB' && countryFrom.code === 'GB') {
      document.getElementsByClassName('cityFrom')[0].value = '';
      setCountryFrom(initialCountryState);
      setCityFrom(initialCityState);
    }
    setCityTo(initialCityState);
    setCountryTo(country);
  };

  const handleChangeCityFrom = (city) => {
    setCityFrom(city);
  };
  const handleChangeCityTo = (city) => {
    setCityTo(city);
  };

  const handleChangeVehicle = (updatedVehicle) => {
    const updatedVehicleCopy = { ...updatedVehicle };
    if (updatedVehicleCopy.id === 0) {
      updatedVehicleCopy.id = Math.random() * 99;
    }
    updateVehicleteState(updatedVehicleCopy);
  };

  const handleClickAddVehicle = () => {
    let updatedVehicles = [vehicle];
    if (vehicles.length > 0) {
      updatedVehicles = [...vehicles, vehicle];
    }
    setVehicles(updatedVehicles);
    updateVehicleteState(defaultVehicle);
  };

  const handleClickDeleteVehicle = (vehicleToDelete) => {
    const updatedVehicles = vehicles.filter((object) => object.id !== vehicleToDelete.id);
    setVehicles(updatedVehicles);
  };

  const getJobObject = () => {
    return {
      jobUuid,
      cityFrom: cityFrom.name,
      countryFrom: countryFrom.code,
      cityTo: cityTo.name,
      countryTo: countryTo.code,
      latitudeFrom: cityFrom.lat,
      longitudeFrom: cityFrom.lng,
      latitudeTo: cityTo.lat,
      longitudeTo: cityTo.lng,
      vehicles,
    };
  };

  const calculateJob = async () => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    const calculationResult = await postCalculation(token, getJobObject());
    if (calculationResult) {
      setDistance(calculationResult.distance);
      setPrice(calculationResult.price);
      setDiscount(calculationResult.discount);
      setJobUuid(calculationResult.jobUuid);
      setVehicleWithPrice(calculationResult.vehicles);
    }
    setIsLoading(false);
    setStep(3);
  };

  const continueJob = async () => {
    if (!isAccepted) {
      setShowValidation(true);
      return;
    }
    navigate(`/order/${jobUuid}`);
  };

  const handleModalClose = () => {
    setShowModal(false);
    navigate('/');
  };

  return (
    <>
      <Header first="Calculate" second=" Price" />
      <Container>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <div className="col-8-lg col-12">
            <Form>
              <Wizard step={step} />
              <Popup
                show={showModal}
                title="Success!"
                body="Job saved"
                secondaryCaption=""
                primaryCaption="OK"
                onClose={handleModalClose}
                onContinue={handleModalClose}
              />
              {step === 1 && (
                <div>
                  <Location
                    countryFrom={countryFrom}
                    countryTo={countryTo}
                    onChangeCountryFrom={handleChangeCountryFrom}
                    onChangeCountryTo={handleChangeCountryTo}
                    cityFrom={cityFrom}
                    cityTo={cityTo}
                    onChangeCityFrom={handleChangeCityFrom}
                    onChangeCityTo={handleChangeCityTo}
                  />
                </div>
              )}
              {step === 2 && (
                <div>
                  <VehicleDetails
                    vehicle={vehicle}
                    vehicles={vehicles}
                    onChangeVehicle={handleChangeVehicle}
                    onClickAddVehicle={handleClickAddVehicle}
                    onClickDeleteVehicle={handleClickDeleteVehicle}
                  />
                </div>
              )}
              <Loader isLoading={isLoading} />

              {step === 3 && !isLoading && (
                <CalculationResult
                  price={price}
                  discount={discount}
                  distance={distance}
                  countryFrom={countryFrom}
                  countryTo={countryTo}
                  cityFrom={cityFrom}
                  cityTo={cityTo}
                  vehicles={vehiclesWithPrice}
                  isAccepted={isAccepted}
                  setIsAccepted={setIsAccepted}
                  showValidation={showValidation}
                />
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 50,
                }}
              >
                {step === 1 && (
                  <Button variant="inverse" onClick={() => navigate('/')}>
                    <LeftArrow className="button-icon-left" />
                    Back
                  </Button>
                )}
                {step === 1 && (
                  <Button
                    variant={cityFrom.name && cityTo.name ? 'primary' : 'default'}
                    disabled={!cityFrom.name || !cityTo.name}
                    onClick={() => setStep(2)}
                  >
                    Add vehicles
                    <RightArrow className="button-icon-right" />
                  </Button>
                )}

                {step === 2 && (
                  <Button
                    variant="inverse"
                    onClick={() => {
                      setCityFrom(initialCityState);
                      setCityTo(initialCityState);
                      setStep(1);
                    }}
                  >
                    <LeftArrow className="button-icon-left" />
                    Back
                  </Button>
                )}
                {step === 2 && !isLoading && (
                  <Button
                    variant={vehicles.length > 0 ? 'primary' : 'default'}
                    disabled={vehicles.length === 0}
                    onClick={() => calculateJob()}
                  >
                    Calculate price
                    <RightArrow className="button-icon-right" />
                  </Button>
                )}
                {step === 2 && isLoading && (
                  <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <Spinner animation="border" />
                    <div>Calculating price...</div>
                  </div>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 50,
                  gap: 8,
                }}
                className="mobile-flex-col"
              >
                {step === 3 && (
                  <Button onClick={() => window.location.reload()}>
                    New calculation
                    <CalculatorLogo className="button-icon-right" />
                  </Button>
                )}
                {step === 3 && price && (
                  <Button variant="primary" onClick={() => continueJob()}>
                    Continue with posting job
                    <RightArrow className="button-icon-right" />
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </div>
      </Container>
    </>
  );
}
