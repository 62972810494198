import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/header';

export default function PaymentReceived() {
  const navigate = useNavigate();
  return (
    <>
      <Header first="Payment" second=" received" />
      <Container>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <div className="col-8-lg col-12">
            <div>
              <p>
                Thank you for your order. Your payment has been received and is being processed.
              </p>
              <p>Your order will be posted as soon as the payment is fully processed.</p>
              <p>
                A confirmation email has been sent to your registered email address with all the
                order details.
              </p>
              <Button className="mt-3" variant="primary" onClick={() => navigate('/')}>
                Back to home
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
