// eslint-disable-next-line import/prefer-default-export
export const countries = [
  { name: 'Belgium', code: 'Be' },
  { name: 'Bulgaria', code: 'Bg' },
  { name: 'Denmark', code: 'Dk' },
  { name: 'Germany', code: 'De' },
  { name: 'Estonia', code: 'Ee' },
  { name: 'France', code: 'Fr' },
  { name: 'Hungary', code: 'Hu' },
  { name: 'Italy', code: 'It' },
  { name: 'Croatia', code: 'Hr' },
  { name: 'Latvia', code: 'Lv' },
  { name: 'Lithuania', code: 'Lt' },
  { name: 'Luxembourg', code: 'Lu' },
  { name: 'Netherlands', code: 'Nl' },
  { name: 'Austria', code: 'At' },
  { name: 'Poland', code: 'Pl' },
  { name: 'Portugal', code: 'Pt' },
  { name: 'Romania', code: 'Ro' },
  { name: 'Slovenia', code: 'Si' },
  { name: 'Slovakia', code: 'Sk' },
  { name: 'Czech Republic', code: 'Cz' },
  { name: 'Spain', code: 'Es' },
  { name: 'Sweden', code: 'Se' },
  { name: 'Switzerland', code: 'Ch' },
  // { name: 'United Kingdom', code: 'Gb' },
];
