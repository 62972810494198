import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { ReactComponent as Journal } from '../../../assets/journal.svg';
import isEmailValid from '../../../util/emailValidation';
import getCountryCaption from '../../../util/getCountryCaption';
import CompanyAddressBook from './companyAddressBook';
import TimeDropdown from './timeDropdown';

export default function CompanyLocation(props) {
  const {
    showValidation,
    onChange,
    onObjectChange,
    defaultLength,
    company,
    languages,
    title,
    keyName,
  } = props;
  const [showModal, setShowModal] = useState(false);

  const getLanguageOptions = () => {
    return languages.map((language) => {
      return (
        <option key={language} value={language}>
          {language}
        </option>
      );
    });
  };

  const handleChange = (e) => {
    const updatedCompany = { ...company };
    updatedCompany[e.target.id] = e.target.value;
    onChange(keyName, updatedCompany);
  };

  const handleOpeningHoursChange = (schedule) => {
    const updatedCompany = { ...company };
    updatedCompany.openingHours = schedule;
    onChange(keyName, updatedCompany);
  };

  const handleClickCloseModal = () => {
    setShowModal(false);
  };

  const handleClickCompany = (e) => {
    const updatedCompany = { ...e };
    updatedCompany.openingHours = company.openingHours;
    onObjectChange(keyName, updatedCompany);
    setShowModal(false);
  };

  return (
    <div>
      {showModal && (
        <CompanyAddressBook
          showModal={showModal}
          onClickClose={handleClickCloseModal}
          onClickCompany={handleClickCompany}
          city={company.city}
          country={company.country}
        />
      )}
      <h2 style={{ marginTop: 50 }}>{title}</h2>
      <h6>We need additional information to complete your order</h6>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={() => setShowModal(true)} variant="inverse">
          <Journal className="button-icon-left" />
          Address book
        </Button>
      </div>
      <Row>
        <Col>
          <Row>
            <Col>
              <Form.Group controlId="name">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type="text"
                  value={company.name ?? ''}
                  onChange={handleChange}
                  maxLength={defaultLength}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <div className="w-75">
              <Form.Group controlId="street">
                <Form.Label>Street*</Form.Label>
                <Form.Control
                  type="text"
                  value={company.street ?? ''}
                  onChange={handleChange}
                  required
                  isInvalid={showValidation && !company.street}
                  maxLength={defaultLength}
                />
              </Form.Group>
            </div>
            <Col>
              <Form.Group controlId="houseNumber">
                <Form.Label>Number*</Form.Label>
                <Form.Control
                  type="text"
                  value={company.houseNumber ?? ''}
                  onChange={handleChange}
                  required
                  isInvalid={showValidation && !company.houseNumber}
                  maxLength={20}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Form.Group controlId="zipcode">
              <Form.Label>Zipcode*</Form.Label>
              <Form.Control
                type="text"
                value={company.zipcode ?? ''}
                onChange={handleChange}
                required
                isInvalid={showValidation && !company.zipcode}
                maxLength={20}
              />
            </Form.Group>
            <Form.Group controlId="city">
              <Form.Label>City*</Form.Label>
              <Form.Control type="text" value={company.city ?? ''} disabled />
            </Form.Group>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="country">
                <Form.Label>Country*</Form.Label>
                <Form.Control disabled value={getCountryCaption(company.country) ?? ''} />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <Form.Group controlId="contactPerson">
                <Form.Label>Contactperson*</Form.Label>
                <Form.Control
                  type="text"
                  value={company.contactPerson ?? ''}
                  onChange={handleChange}
                  required
                  isInvalid={showValidation && !company.contactPerson}
                  maxLength={defaultLength}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="telephone">
                <Form.Label>Contact telephone nr.*</Form.Label>
                <Form.Control
                  type="text"
                  value={company.telephone ?? ''}
                  onChange={handleChange}
                  required
                  isInvalid={showValidation && !company.telephone}
                  maxLength={20}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="email">
                <Form.Label>Contact email*</Form.Label>
                <Form.Control
                  type="email"
                  value={company.email ?? ''}
                  onChange={handleChange}
                  isInvalid={showValidation && !isEmailValid(company.email)}
                  maxLength={defaultLength}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="language">
                <Form.Label>Contact language*</Form.Label>
                <Form.Select
                  onChange={handleChange}
                  isInvalid={showValidation && !company.language}
                  value={company.language ?? ''}
                >
                  {getLanguageOptions()}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="openingTimesF">
                <Form.Label>Opening hours*</Form.Label>
                <TimeDropdown
                  weekSchedule={company.openingHours}
                  onChange={handleOpeningHoursChange}
                  companyType="openingTimes"
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
