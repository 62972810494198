export default async function getCompany(token) {
  if (token) {
    const postFix =
      process.env.REACT_APP_IS_SPRING_BACKEND_ACTIVE === 'true' ? 'company' : 'companies/1';
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MENDIX_HOST}/rest/transporting-highway/v1/${postFix}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const jsonData = await response.json();
      if (response.ok) {
        const company = {
          name: jsonData.Name,
          street: jsonData.Street,
          houseNumber: jsonData.HouseNumber,
          zipcode: jsonData.Zipcode,
          city: jsonData.City,
          country: jsonData.Country ?? 'Nl',
          vatNumber: jsonData.VatNumber,
          email: jsonData.Email ?? '',
          language: jsonData.Language ?? 'Nl',
          companyId: jsonData.CompanyId ?? '',
          contactPerson: jsonData.ContactPerson ?? '',
        };
        return company;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  return null;
}
