import React from 'react';
import { ReactComponent as CheckImage } from '../../../assets/check-double-line.svg';
import getJobTagColor from '../../../util/getJobTagColor';

const style = {
  mainDiv: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    alignContent: 'flex-start',
    flex: 1,
    minWidth: 150,
  },
  header: {
    fontWeight: 700,
    paddingLeft: 0,
  },
  data: {
    fontWeight: 400,
    paddingLeft: 0,
    paddingTop: 0,
  },
  text: {
    header: { fontWeight: 600, display: 'flex', alignItems: 'center' },
  },
};

export default function OrderDetails({ formData }) {
  return (
    <div style={{ paddingLeft: 10, paddingRight: 10 }}>
      <div className="px-4 py-3 light-background">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            gap: 10,
          }}
        >
          <div style={style.mainDiv}>
            <div style={style.header}>Order ID</div>
            <div style={style.data}>{formData.orderNumber}</div>
          </div>
          <div style={style.mainDiv}>
            <div style={style.header}>Order date</div>
            <div style={style.data}>
              {formData.createdDate
                ? new Date(formData.createdDate).toLocaleDateString('nl-NL', { timeZone: 'UTC' })
                : ''}
            </div>
          </div>
          <div style={style.mainDiv}>
            <div style={style.header}>Ready from</div>
            <div style={style.data}>
              {formData.readyFrom
                ? new Date(formData.readyFrom).toLocaleDateString('nl-NL', { timeZone: 'UTC' })
                : ''}
            </div>
          </div>
          <div style={style.mainDiv}>
            <div style={style.header}>Status</div>
            <div style={style.data} className={`tag ${getJobTagColor(formData.status)}`}>
              {formData.status === 'ToPlan' ? 'To plan' : formData.status}
            </div>
          </div>
          <div style={style.mainDiv}>
            <div style={style.header}>Price</div>
            <div style={style.data}>{`€ ${formData.price}`}</div>
          </div>
        </div>
        {formData.additionalInfo && (
          <div className="mt-2">
            <div style={style.text.header}>
              <CheckImage className="text-icon-left" />
              Extra info
            </div>
            {formData.additionalInfo}
          </div>
        )}
      </div>
    </div>
  );
}
