export default async function postOrderPayment(token, id) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_MENDIX_HOST}/rest/stripe/v1/checkout?jobUuid=${id}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const url = await response.text();

    if (url) {
      window.location.href = url;
    } else {
      console.error('Location header not found in the response');
    }

    // window.location.href = response.url;
  } catch (error) {
    console.error('Error:', error);
  }
}
